/* istanbul ignore file */

import { axiosInstance } from './axiosInstance';
import { IRoad, CODE_TABLE } from 'utils/constants';
import { Dispatch } from 'redux';
import { setCodeTable } from 'store/codeTables/actions';
import qs from 'qs';

interface IFetchAddressParams {
  postalCode: string;
}

export interface IFetchAddressData {
  unformatted: string;
  buildingName: string;
  roadName: string;
  blockNo: string;
  postalCode: string;
}

export const fetchAddress = async ({ postalCode }: IFetchAddressParams): Promise<IFetchAddressData[]> => {
  return (await axiosInstance.get(`/address?postalCode=${postalCode}`)).data;
};

interface IFetchRoadsResponse {
  roads: IRoad[];
}

export const fetchRoads = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance.get<IFetchRoadsResponse>('/road').then(({ data: { roads } }) => {
    dispatch(setCodeTable(CODE_TABLE.ROADS, roads));
  });
};

interface IFetchRoadsBySearchTerm {
  searchTerm: string;
  limit?: number;
}

export const fetchRoadsBySearchTerm = async ({
  searchTerm = '',
  limit = 5,
}: IFetchRoadsBySearchTerm): Promise<IRoad[]> => {
  const searchParams = qs.stringify({
    searchTerm,
    limit,
  });
  return axiosInstance.get<IFetchRoadsResponse>(`/road?${searchParams}`).then(({ data: { roads } }) => roads);
};

export const fetchRoadsByCode = async (codes: string[]): Promise<IRoad[]> => {
  if (!codes.length) return [];
  const query = qs.stringify({ codes });
  return axiosInstance.get<IFetchRoadsResponse>(`/road?${query}`).then(({ data: { roads } }) => roads);
};
