export const alreadyEnabledFlags = {
  enableDspV3: false,
  enableConsolidatedDeclaration: false,
  enableCreateSubmissionV2_DSP: false,
  enableCreateSubmissionV2_35Gateway: false,
  enableCreateSubmissionV2_FullDemolition: false,
  enableCreateSubmissionV2_EWPlanApplication: false,
  enableCreateSubmissionV2_StPlanPermit: false,
  enableCreateSubmissionV2_PermitToCarrySTWorks: false,
  enableBCALodgementStPlanPermit: false,
  enableBCALodgementDifferentSTWORKSQPNotification: false,
  enableTopCscRequirementsTable: false,
  enableTopOverallV2: false,
  enablePaidEarlier: false,
  enableCreateProjectV2: false,
  enableFullDemolitionV3: false,
  enableValidLicenseCheck: false,
  enableEditAdditionalDetailsJtc: false,
  enableShowAutoAssignedProjectParty: false,
  enableEditRelatedStructuralPlans: false,
  enableRemovalOfAppointedResponsibilities: false,
  enableStPlanAndPermitSameQp: false,
  enableRequireChangeForPlanAmendment: false,
  enableCscPubAssignProjectParty: false,
  enableFullDemolitionURAClearanceCheck: false,
  enableConcurrentCreationOfDGCGPG: false,
  enablePermitRevocation: false,
  enableCreationWizardV2ForDG: false,
  enableCreationWizardV2ForPG: false,
  enableCreationWizardV2ForCG: false,
};

export const defaultFeatureFlags = {
  hasLoaded: false,

  // devtools
  enableSandbox: false,
  showFormBuilder: false,
  enableQuickFilePolling: false,
  enableSubmissionHelpers: false,
  enableQuickSubmissionLock: false,
  enableMockAccountDashboard: false,
  enableToggleFeatureFlagPanel: false,

  enableTrainingEnv: false,
  restrictAccessToBeta: false,

  ...alreadyEnabledFlags,

  // not enabled in prd
  enableMCPreCheck: false,
  enableSiteInspection: false,
  enableSiteInspectionSoftBooking: false,
  enableFixedInstallationIndependentTechclearance: false,
  enableNewFormAvailableBanner: false,
  enableDeleteProject: false,
  enableAppointmentsPageV2: false,
  enableWithdrawSubmission: false,
  enableWithdrawResubmission: false,
  enableWithdrawAmendment: false,
  enableFullDemolitionEnhancementsFor35Gateway: false,
  enableQc: false,
  enableQcGatekeep: false,
  enableHideModelCheckSubmitButton: false,
  enableTemporaryBuildingStructureDevType: false,
  enableNewDeveloperBannersOnAddMemberPage: false,
  enableReadOnlyNameForMyInfo: false,
  enableDisallowAmendmentBuilderCert: false,
  enableHandleLodgementSchemeType: false,
  enableAllAppointedQPsFix: false,
  enableNParksCSCCreationBanner: false,
  enableCaptcha: false,
  enableAddLBSecondaryPersonnel: false,
  enableURATechnicalClearanceV3: false,
};

export type FeatureFlags = typeof defaultFeatureFlags;
