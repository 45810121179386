import { lazy } from 'react';

const FIRST_LOAD_KEY = 'page-first-load';

export const lazyWithRetry: typeof lazy = (componentImport) =>
  lazy(async () => {
    const fresh = JSON.parse(window.localStorage.getItem(FIRST_LOAD_KEY) || 'true');

    try {
      const component = await componentImport();
      window.localStorage.removeItem(FIRST_LOAD_KEY);

      return component;
    } catch (error) {
      if (fresh) {
        // Assuming that the user is not on the latest version of the application, refresh immediately
        window.localStorage.setItem(FIRST_LOAD_KEY, 'false');
        window.location.reload();
      }

      // At this point, page has been reloaded. Assuming that user is already using the latest app version, let the app crash and raise the error
      throw error;
    }
  });
