import { createContext, useContext } from 'react';
import React from 'react';
import { CheckCircle } from 'ui';
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from 'apis/fetch';
import { queryClient } from 'apis/client';
import { addSuccessToast } from 'ui/components/Notification';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from 'feature-flags/useFeatureFlags';

type ITrainingUser = {
  name: string;
  email: string;
};

interface ITrainingProfile {
  record?: {
    uuid: string;
    name: string;
    role?: string;
    subRole?: string;
  };
}

type AuthContextType = {
  isAuthenticated: boolean;
  trainingUser?: ITrainingUser | null;
  requestOtp: (email: string) => Promise<string>;
  loginTrainingUser: (params: { email: string; otp: string; otpId?: string }) => Promise<void>;
  registerTrainingUser: (params: {
    name: string;
    firmUen: string;
    firmName: string;
    role: string;
    regNumber: string;
    email: string;
    otp: string;
    otpId: string;
  }) => Promise<void>;
  logout: () => Promise<void>;
  trainingAccountProfileById: (accountUuid: string) => UseQueryResult<ITrainingProfile, unknown>;
};

const TrainingEnvAuthContext = createContext<AuthContextType | undefined>(undefined);

export const useTrainingEnvAuth = () => {
  const context = useContext(TrainingEnvAuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const TrainingEnvAuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const { enableTrainingEnv, enableCaptcha } = useFeatureFlags();

  const { data: trainingUserAccountData, isSuccess } = useQuery({
    enabled: Boolean(enableTrainingEnv),
    queryKey: ['profile'],
    queryFn: async ({ signal }) =>
      api<{ success: boolean; user: ITrainingUser }>('/training-env/training-users/account', { signal }),
    retry: false,
  });

  const trainingAccountProfileById = (accountUuid: string) =>
    useQuery({
      enabled: Boolean(accountUuid),
      queryKey: [`training-account-${accountUuid}`],
      queryFn: ({ signal }) => api<ITrainingProfile>(`/training-env/training-accounts/${accountUuid}`, { signal }),
    });

  const requestOtpMutation = useMutation({
    mutationFn: async (email: string) => {
      const response = await api<{ otpId: string }>('/training-env/training-users/request-otp', {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      return response.otpId;
    },
  });

  const logoutOtpMutation = useMutation({
    mutationFn: async () => {
      await api<{ success: boolean }>('/training-env/training-users/logout', {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: {},
      });
    },
    onSuccess: async () => {
      queryClient.clear();
    },
  });

  const loginTrainingUserMutation = useMutation({
    mutationFn: async (params: { email: string; otp: string; otpId?: string }) => {
      const { success } = await api<{ success: boolean; user: ITrainingUser }>('/training-env/training-users/login', {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: {
          ...params,
        },
      });
      if (success) {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
        queryClient.invalidateQueries({ queryKey: ['training-user'] });
      }
    },
    onSuccess: async () => {
      addSuccessToast({
        icon: <CheckCircle />,
        message: `${t('trainingEnv:toast.loginSuccess')}`,
      });
    },
  });

  const registerTrainingUserMutation = useMutation({
    mutationFn: async (params: {
      name: string;
      firmUen: string;
      firmName: string;
      role: string;
      regNumber: string;
      email: string;
      otp: string;
      otpId: string;
    }) => {
      let token;
      let wafHeaderToken = {};
      if (enableCaptcha) {
        token = await window.AwsWafIntegration.getToken();
        wafHeaderToken = { 'x-aws-waf-token': token };
      }

      const { success } = await api<{ success: boolean; user: ITrainingUser }>(
        '/training-env/training-users/register',
        {
          headers: { 'content-type': 'application/json', ...wafHeaderToken },
          method: 'POST',
          body: JSON.stringify(params),
        },
      );
      if (success) {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
        queryClient.invalidateQueries({ queryKey: ['training-user'] });
      }
    },

    onSuccess: async () => {
      addSuccessToast({
        icon: <CheckCircle />,
        message: `${t('trainingEnv:toast.registerSuccess')}`,
      });
    },
  });

  const isAuthenticated = isSuccess && !!trainingUserAccountData.user.email;

  return (
    <TrainingEnvAuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        trainingUser: trainingUserAccountData?.user,
        requestOtp: requestOtpMutation.mutateAsync,
        loginTrainingUser: loginTrainingUserMutation.mutateAsync,
        registerTrainingUser: registerTrainingUserMutation.mutateAsync,
        logout: logoutOtpMutation.mutateAsync,
        trainingAccountProfileById: trainingAccountProfileById,
      }}
    >
      {children}
    </TrainingEnvAuthContext.Provider>
  );
};
