import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container } from 'ui';
import { confirmAdditionToProject } from 'apis';
import { ReactComponent as AccessDeniedLogo } from 'assets/accessDenied.svg';

export const ErrorPage: React.FC<{
  header: string;
  subtext: string;
  buttonLabel: string;
  onClickButton: () => void;
}> = ({ header, subtext, buttonLabel, onClickButton }) => (
  <div
    data-cy="member-invite-page_error_member-invite-page-same-role-error"
    className="flex flew-grow justify-center items-center w-full"
  >
    <div className="flex flex-col items-center space-y-8">
      <div className="flex flex-col items-center gap-y-12">
        <span className="text-warning">
          <AccessDeniedLogo />
        </span>
        <Container>
          <div className="flex-col flex space-y-2 lg:col-start-4 col-span-4 sm:col-start-2 sm:col-span-6">
            <span className="text-gray-80 text-center heading-4-bold">{header}</span>
            <span
              className="text-center text-gray-60 body-regular whitespace-pre-wrap"
              data-cy="member-invite-page_error_member-invite-page-subtext"
            >
              {subtext}
            </span>
          </div>
        </Container>
        <Container>
          <div className="col-span-full flex justify-center">
            <div className="w-full sm:w-max">
              <Button
                data-cy="member-invite-page_error_member-invite-page-same-role-error_button"
                label={buttonLabel}
                variant="primary"
                onClick={onClickButton}
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  </div>
);

export const MemberInvitePageInvitationDeletedError: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('memberInvitePage:noLongerValidInvitation.header')}
      subtext={t('memberInvitePage:noLongerValidInvitation.subText')}
      buttonLabel={t('memberInvitePage:noLongerValidInvitation.button.label')}
      onClickButton={() => navigate(`/auth/projects`)}
    />
  );
};

export const MemberInvitePageCompanyAccountRequiredError: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('memberInvitePage:memberInviteCompanyAccountRequiredError:header')}
      subtext={t('memberInvitePage:memberInviteCompanyAccountRequiredError:subtext')}
      buttonLabel={t('memberInvitePage:memberInviteCompanyAccountRequiredError:goToMyProjectsButton')}
      onClickButton={() => navigate(`/auth/projects`)}
    />
  );
};

export const MemberInvitePageInvalidCompanyRoleError: React.FC<{
  refNumber: string;
  projectId: string;
  inviteId: string;
}> = ({ refNumber, projectId, inviteId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('memberInvitePage:memberInviteOrganisationRoleError:header', { refNumber })}
      subtext={t('memberInvitePage:memberInviteOrganisationRoleError:subtext')}
      buttonLabel={t('memberInvitePage:memberInviteOrganisationRoleError:backToProjectOverviewButton')}
      onClickButton={async () => {
        await confirmAdditionToProject(inviteId); // triggers email sending to project coordinator
        navigate(`/auth/projects/${projectId}`);
      }}
    />
  );
};

export const MemberInvitePageInvalidLicensedBuilderSubrole: React.FC<{
  refNumber: string;
  projectId: string;
  memberId: string;
}> = ({ refNumber, projectId, memberId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('memberInvitePage:memberInviteLicensedBuilderSubroleError:header', { refNumber })}
      subtext={t('memberInvitePage:memberInviteLicensedBuilderSubroleError:subtext')}
      buttonLabel={t('memberInvitePage:memberInviteLicensedBuilderSubroleError:viewMyProfileButton')}
      onClickButton={async () => {
        navigate(`/auth/projects/${projectId}/member/${memberId}`);
      }}
    />
  );
};

export const MemberInvitePageNoNewRolesError: React.FC<{
  refNumber: string;
  projectId: string;
  inviteId: string;
  memberId: string;
}> = ({ refNumber, projectId, inviteId, memberId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('memberInvitePage:memberInviteSameRoleError:header', { refNumber })}
      subtext={t('memberInvitePage:memberInviteSameRoleError:subtext')}
      buttonLabel={t('memberInvitePage:memberInviteSameRoleError:viewMyProfileButton')}
      onClickButton={async () => {
        await confirmAdditionToProject(inviteId); // triggers email sending to project coordinator
        navigate(`/auth/projects/${projectId}/member/${memberId}`);
      }}
    />
  );
};

export const NoPermissionPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('errors:noPermissionPage:header')}
      subtext={t('errors:noPermissionPage:subtext')}
      buttonLabel={t('errors:noPermissionPage:button:goToMyProjects')}
      onClickButton={() => navigate(`/auth/projects`)}
    />
  );
};

export const NotPcPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('errors:notPC:header')}
      subtext={t('errors:notPC:subtext')}
      buttonLabel={t('errors:notPC:button:goToMyProjects')}
      onClickButton={() => navigate(`/auth/projects`)}
    />
  );
};

export const SubmissionDeletedPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const path = useParams()['*'];

  const projectId = path?.split('/')?.[0];
  return (
    <ErrorPage
      header={t('errors:submissionDeleted:header')}
      subtext={t('errors:submissionDeleted:subtext')}
      buttonLabel={t('errors:submissionDeleted:button:goToSubmissionWorkspace')}
      onClickButton={() => navigate(`/auth/projects/${projectId}/submissions`)}
    />
  );
};

export const ProjectIsNoLongerAvailablePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorPage
      header={t('errors:projectNoLongerAvailable:header')}
      subtext={t('errors:projectNoLongerAvailable:subtext')}
      buttonLabel={t('errors:projectNoLongerAvailable:button:goToMyProjects')}
      onClickButton={() => navigate(`/auth/projects`)}
    />
  );
};
