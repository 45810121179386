const {
  REACT_APP_VERSION: version = 'v1.0.0',
  REACT_APP_STRIPE_KEY: stripeKey = '',
  REACT_APP_UPDATED_DATE: lastUpdatedDate = 'today',
  REACT_APP_WAF_APIKEY: wafApiKey = '',
} = process.env;

const getStripeConnectAccountId = (agencyAbreviation: string) =>
  process.env[`REACT_APP_CONNECT_ACCOUNT_${agencyAbreviation.toUpperCase()}`];

export const config = {
  version,
  stripeKey,
  wafApiKey,
  getStripeConnectAccountId,
  lastUpdatedDate,
};
