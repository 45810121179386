import React, { ErrorInfo } from 'react';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import NotFoundPage from './Errors/NotFoundPage';
import {
  MemberInvitePageCompanyAccountRequiredError,
  MemberInvitePageInvalidCompanyRoleError,
  MemberInvitePageInvalidLicensedBuilderSubrole,
  MemberInvitePageInvitationDeletedError,
  MemberInvitePageNoNewRolesError,
  NoPermissionPage,
  NotPcPage,
  ProjectIsNoLongerAvailablePage,
  SubmissionDeletedPage,
} from './Errors/ErrorPage';
import { FetchError } from '../apis/fetch';

export enum ERROR_CODE {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  NO_PERMISSION = 'NO_PERMISSION',
  ONLY_PC_PCA = 'ONLY_PC_PCA',
  INVITATION_DELETED = 'INVITATION_DELETED',
  INVITATION_MISMATCHED_IDENTITY = 'INVITATION_MISMATCHED_IDENTITY',
  INVITATION_COMPANY_ACCOUNT_REQUIRED = 'INVITATION_COMPANY_ACCOUNT_REQUIRED',
  INVITATION_MISMATCHED_UEN = 'INVITATION_MISMATCHED_UEN',
  INVITATION_MEMBER_DELETED = 'INVITATION_MEMBER_DELETED',
  INVITATION_INVALID_COMPANY_ROLE = 'INVITATION_INVALID_COMPANY_ROLE',
  INVITATION_INVALID_BUILDER_SUBROLE = 'INVITATION_INVALID_BUILDER_SUBROLE',
  INVITATION_NO_NEW_ROLES = 'INVITATION_NO_NEW_ROLES',
  SUBMISSION_DELETED = 'SUBMISSION_DELETED',
  FILE_DOES_NOT_EXISTS_IN_STORAGE = 'FILE_DOES_NOT_EXISTS_IN_STORAGE',
  INVALID_PAYMENT = 'INVALID_PAYMENT',
  PROJECT_DELETED = 'PROJECT_DELETED',
  PROJECT_CLOSED = 'PROJECT_CLOSED',
}

/**
 * Error codes mapping MUST be the same as the `CX_ERROR_CODE_MAPPING`
 * in apps/internet-backend/src/utils/constants.ts
 */
export const ERROR_CODE_MAPPING: { [key in ERROR_CODE]: string } = {
  [ERROR_CODE.UNKNOWN_ERROR]: '1001',
  [ERROR_CODE.NOT_LOGGED_IN]: '1002',
  [ERROR_CODE.SUBMISSION_DELETED]: '1007',
  [ERROR_CODE.NO_PERMISSION]: '1008',
  [ERROR_CODE.ONLY_PC_PCA]: '1009',
  [ERROR_CODE.INVITATION_DELETED]: '1010',
  [ERROR_CODE.INVITATION_MISMATCHED_IDENTITY]: '1011',
  [ERROR_CODE.INVITATION_COMPANY_ACCOUNT_REQUIRED]: '1012',
  [ERROR_CODE.INVITATION_MISMATCHED_UEN]: '1013',
  [ERROR_CODE.INVITATION_MEMBER_DELETED]: '1014',
  [ERROR_CODE.INVITATION_INVALID_COMPANY_ROLE]: '1015',
  [ERROR_CODE.INVITATION_NO_NEW_ROLES]: '1016',
  [ERROR_CODE.FILE_DOES_NOT_EXISTS_IN_STORAGE]: '1018',
  [ERROR_CODE.INVALID_PAYMENT]: '1019',
  [ERROR_CODE.PROJECT_CLOSED]: '1020',
  [ERROR_CODE.PROJECT_DELETED]: '1021',
  [ERROR_CODE.INVITATION_INVALID_BUILDER_SUBROLE]: '1022',
};

export const getNumericalErrorCode = (errorCode: keyof typeof ERROR_CODE) => ERROR_CODE_MAPPING[errorCode];

export const isCxErrorCode = (errorCode: keyof typeof ERROR_CODE): boolean =>
  ERROR_CODE_MAPPING[errorCode] !== undefined;

export class ErrorBoundary extends React.Component {
  state: {
    pathname: string;
    errorData: {
      errorCode?: string;
      metaData?: Record<string, unknown>;
    };
  } = {
    pathname: window.location.pathname,
    errorData: {},
  };

  // This function is called when child components throw an error to update state
  // https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
  public static getDerivedStateFromError(err: Error) {
    if (err instanceof AxiosError) {
      return { errorData: err.response?.data };
    }

    // Compatibility with FetchError
    // API errors thrown in a ReactQuery hook will be propagated up to the ErrorBoundary
    if (err instanceof FetchError) {
      return { errorData: err.errorData };
    }
  }

  public componentDidCatch(error: AxiosError, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  componentDidUpdate() {
    const pathname = window.location.pathname;
    if (this.state.pathname !== pathname) {
      this.setState({
        errorData: {},
        pathname,
      });
    }
  }

  public render() {
    const { errorCode, metaData } = this?.state?.errorData ?? {};

    const refNumber = typeof metaData?.refNumber === 'string' ? metaData?.refNumber : '';
    const projectId = typeof metaData?.projectId === 'string' ? metaData?.projectId : '';
    const inviteId = typeof metaData?.inviteId === 'string' ? metaData?.inviteId : '';
    const memberId = typeof metaData?.memberId === 'string' ? metaData?.memberId : '';

    switch (errorCode) {
      case ERROR_CODE.NO_PERMISSION:
      case ERROR_CODE.INVITATION_MISMATCHED_IDENTITY:
      case ERROR_CODE.NOT_LOGGED_IN:
        return <NoPermissionPage />;
      case ERROR_CODE.ONLY_PC_PCA:
        return <NotPcPage />;
      case ERROR_CODE.SUBMISSION_DELETED:
        return <SubmissionDeletedPage />;
      case ERROR_CODE.INVITATION_MISMATCHED_UEN:
      case ERROR_CODE.INVITATION_MEMBER_DELETED:
        return <NotFoundPage isLoggedIn={true} />;
      case ERROR_CODE.INVITATION_DELETED:
        return <MemberInvitePageInvitationDeletedError />;
      case ERROR_CODE.INVITATION_COMPANY_ACCOUNT_REQUIRED:
        return <MemberInvitePageCompanyAccountRequiredError />;
      case ERROR_CODE.PROJECT_CLOSED:
      case ERROR_CODE.PROJECT_DELETED:
        return <ProjectIsNoLongerAvailablePage />;
      case ERROR_CODE.INVITATION_INVALID_COMPANY_ROLE:
        return (
          <MemberInvitePageInvalidCompanyRoleError refNumber={refNumber} projectId={projectId} inviteId={inviteId} />
        );
      case ERROR_CODE.INVITATION_NO_NEW_ROLES:
        return (
          <MemberInvitePageNoNewRolesError
            refNumber={refNumber}
            projectId={projectId}
            inviteId={inviteId}
            memberId={memberId}
          />
        );
      case ERROR_CODE.INVITATION_INVALID_BUILDER_SUBROLE:
        return (
          <MemberInvitePageInvalidLicensedBuilderSubrole
            refNumber={refNumber}
            projectId={projectId}
            memberId={memberId}
          />
        );
    }

    if (!isEmpty(this.state.errorData)) {
      return <NotFoundPage isLoggedIn={true} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
